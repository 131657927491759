import React from "react";

function ButtonNavigation({ name, icon1, icon2, onClick }) {
  return (
    <button
    onClick={onClick}
      style={{
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        border: "none",
        padding: "20px 14px",
        borderRadius: "6px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", gap: "16px" }}>
          <img src={icon1} alt="" />
          <p
            style={{
              marginBottom: "0px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#333",
            }}
          >
            {name}
          </p>
        </div>
        <div>
          <img src={icon2} alt="" />
        </div>
      </div>
    </button>
  );
}

export default ButtonNavigation;
