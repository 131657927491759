import React, { useEffect } from 'react'
import CommonLayout from '../layout/CommonLayout'
import ButtonNavigation from '../components/ButtonNavigation'
import orderHistory from "../assets/images/icons/orderHistory.svg"
import signout from "../assets/images/icons/signOut.svg"
import goIcon from "../assets/images/icons/checkCouponArrow.svg";
import UseHeader from '../components/UserHeader'
import { useNavigate, useParams } from 'react-router-dom';

function OrderHistoryandSignOut() {
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
        navigate(`/${userId}/login`);
    }
}, [navigate, userId]);

  const OrderHistoryHandeller =()=>{
    navigate(`/${userId}/order-history`);
  }
  const SignoutHandeller =()=>{
    const token = localStorage.getItem('token');
    if (token !== null) {
      localStorage.removeItem('token');
      localStorage.removeItem('usermob');
      navigate(`/${userId}/login`);
    }   
  }
  return (
    <CommonLayout>
        <UseHeader /> 
        <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px 20px",
          paddingTop: "30px",
          gap: "12px",
        }}
      >
        <ButtonNavigation onClick={OrderHistoryHandeller} name={"Order History"} icon1={orderHistory} icon2={goIcon}/>
        <ButtonNavigation onClick={SignoutHandeller} name={"Sign out"} icon1={signout} icon2={goIcon}/>
      </div>
    </CommonLayout>
  )
}

export default OrderHistoryandSignOut