import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryList: [],
  subCategoryList: [],
};

export const fetchCategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    getCategory: (state, action) => {
      state.categoryList = action.payload;
    },
    getSubcategory: (state, action) => {
      state.subCategoryList = action.payload;
    },
  },
});

export const { getCategory,getSubcategory } = fetchCategorySlice.actions;
export default fetchCategorySlice.reducer; //export reducer to store.js file
