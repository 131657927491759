import React, { useEffect, useState } from "react";
import axios from "axios";
import CommonLayout from "../layout/CommonLayout";
import BackClick from "../components/BackClick";
import locationicon from "../assets/images/icons/locationIcon.svg";
import goIcon from "../assets/images/icons/checkCouponArrow.svg";
import { useSelector } from "react-redux";
import { load } from "@cashfreepayments/cashfree-js";
import { useNavigate, useParams } from "react-router-dom";
import randomString from "randomstring";
import { useLocation } from 'react-router-dom';
import AddressModal from "../components/modal/AddressModal";

function AddPaymentAddress() {
  const [OrderId, setOrderId] = useState();
  const location = useLocation();
  const totalAmount = location.state?.totalAmount;
  const newproducts = location.state?.products;
  const { userId } = useParams();
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [selected, setSelected] = useState(null);
  const token = localStorage.getItem('token');
  const addressList = useSelector((state) => state.address?.addressList);
  const orderDateTime = new Date().toLocaleDateString('en-GB') + ' ' + new Date().toLocaleTimeString('en-GB');
  const [refreshAddresses, setRefreshAddresses] = useState(false);

  let cashfree;
  const initializeSDK = async function () {
    cashfree = await load({
      mode: process.env.REACT_APP_CASHFREE_MODE,
    });
  };
  initializeSDK();

  const makePayment = (
    amount = totalAmount,
    orderId = randomString.generate(10),
    customerId = randomString.generate(4),
    customerContactNumber = "6467474763"
  ) => {
    const orderData = {
      order_amount: amount,
      order_currency: "INR",
      order_id: orderId,
      customer_details: {
        customer_id: customerId,
        customer_phone: customerContactNumber,
      },
      order_meta: {
        return_url:
          "https://www.cashfree.com/devstudio/preview/pg/web/popupCheckout?order_id=" +
          orderId,
      },
    };
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/pay", orderData)
      .then(async (response) => {
        await pay(response.data.payment_session_id, orderId, amount);
      })
      .catch((error) => console.log(error));
  };

  const pay = async (paymentSessionId, orderId, amount) => {
    let checkoutOptions = {
      paymentSessionId: paymentSessionId,
      redirectTarget: "_modal",
    };
    cashfree.checkout(checkoutOptions).then(async (result) => {
      if (result.error) {
        navigate(`/${userId}/paymentfaild`);
      } else if (result.paymentDetails) {

        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}customer/create-order`;
        const payload = {
          products: newproducts.map((item) => ({
            productId: item.id,
            variantId: item.variantId,
            quantity: item.quantity,
          })),
          addressId: selected,
          amount: amount,
          totalAmt: totalAmount,
        };

        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
            body: JSON.stringify(payload),
          });

          const result = await response.json();
          if (response.ok) {
            console.log(result.message);
            let NeworderId = result.order.orderId;
            navigate(`/${userId}/order-successfull`, {
              state: {
                neworderId: NeworderId,
                paymentType: "Prepaid",
                orderDateTime,
                orderAmount: amount,
              },
            });
            localStorage.removeItem("cart");
          } else {
            console.error(result);
          }
        } catch (error) {
          console.error(error.message);
        }
      }

    });
  };


  useEffect(() => {
    const fetchAddresses = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}customer/get-all-addresses`, {
          method: 'GET',
          headers: {
            "token": token,
          },
        });
        const data = await response.json();
        setAddresses(data.addresses || []);

      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };

    fetchAddresses();
  }, [token, refreshAddresses]);


  return (
    <CommonLayout>
      <BackClick />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px 20px",
          paddingTop: "30px",
          gap: "12px",
        }}
      >
        <button
          style={{
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            border: "none",
            padding: "20px 14px",
            borderRadius: "6px",
            width: "100%",
          }}
          data-bs-toggle="modal"
          data-bs-target="#addressModal"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", gap: "16px" }}>
              <img src={locationicon} alt="" />
              <p
                style={{
                  marginBottom: "0px",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#333",
                }}
              >
                {addressList?.length > 0
                  ? "Deliver at:"
                  : "Add your shipping address"}
              </p>
            </div>
            <div>
              <img src={goIcon} alt="" />
            </div>
          </div>
        </button>

        <AddressModal setRefreshAddresses={setRefreshAddresses} />
        <div className="w-100">
          {addresses?.map((item, i) => (
            <div key={i} className="d-flex gap-3" style={{
              background: '#fff',
              padding: '15px',
              paddingBottom: '0px',
              borderRadius: '6px',
              marginBottom: '10px'
            }}>
              <div style={{
                width: '10%',
                position: 'relative',
              }}>
                <input
                  type="radio"
                  name="address"
                  style={{
                    transform: 'scale(2)',
                    position: 'absolute',
                    top: '11px',
                    left: '4px',
                  }}
                  defaultChecked={selected === item._id} // This ensures it reflects the state
                  onChange={() => setSelected(item._id)} // Update the selected address
                />
              </div>
              <p style={{ fontSize: '14px', color: '#333', width:'80%' }}>
                {item.name}, {item.address}, {item.city}, {item.state}, {item.zip} <br />
                {item.email} <br />
                {item.mobile}
              </p>
            </div>
          ))}

        </div>


        <button
          style={{
            background: "#fff",
            display: "flex",
            justifyContent: "space-between",
            border: "none",
            padding: "20px 14px",
            borderRadius: "6px",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "16px" }}>
            <input
              type="radio"
              // name="address"
              id=""
              style={{
                transform: "scale(2)"
              }}
              defaultChecked="true"
            />
            <p
              style={{
                marginBottom: "0px",
                fontSize: "16px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              Online Payment
            </p>
          </div>
          <div>
            <img src={goIcon} alt="" />
          </div>
        </button>

        <button
          style={{
            backgroundColor: selected ? "#3371A5" : "#d3d3d3", // Change color if disabled
            color: "#fff",
            border: "none",
            borderRadius: "10px",
            padding: "14px",
            width: "100%",
            marginTop: "30px",
            fontWeight: "600",
          }}
          onClick={() => { makePayment() }}
          disabled={!selected} // Disable button if no address is selected
        >
          Order Now
        </button>
      </div>
    </CommonLayout>
  );
}

export default AddPaymentAddress;
