import React from 'react'
import CommonLayout from '../layout/CommonLayout'
import UseHeader from '../components/UserHeader'
import productimg from '../assets/images/cart2.png'

function ProductPreview() {
    return (
        <CommonLayout>
            <UseHeader />
            <div className='product-thumbnail'>
                <img src={productimg} width={'100%'} height={'500px'} />
            </div>
            <div className="product-des">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className='px-2 pb-3'>
                            <h4 className='m-0'>Imaaya Ajrakh Sari</h4>
                            <p>(Variant name: Value)</p>
                        </div>


                        <div className='product_ad_des'>
                            <div className='d-flex justify-content-between'>
                                <strong>SKU ID :</strong>
                                <p>74044</p>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <strong>Quantity : </strong>
                                <p>3</p>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <strong>Quantity : </strong>
                                <p>3</p>
                            </div>
                        </div>

                        <p className='py-3 px-2'>Handcrafted - Hand block printed with environment friendly natural dyes, modal silk saree with symmetrical geometric patterns, symbolising elements in nature and inspired by Persian art . A beautiful ornamental pattern on the palla is sure to take your breath away. Accessorised with tassles. <br /><br />

                            Base fabric is machine made and not handwoven. Since the saree is naturally dyed, it will not be as bright as it appears on the screen.</p>

                        <div className='row more_des'>
                            <div className='col-5 p-0'>
                                <div className='d-flex align-items-center gap-2'>
                                    <strong>Material :</strong>
                                    <p>Silk</p>
                                </div>
                                <div className='d-flex align-items-center gap-2 mt-2'>
                                    <strong>Length:</strong>
                                    <p> 6.3 Mtr</p>
                                </div>

                            </div>
                            <div className='col-7  p-0'>
                                <div className='d-flex align-items-center gap-2'>
                                    <strong>Self Blouse Piece:</strong>
                                    <p>Yes</p>
                                </div>

                                <div className='d-flex align-items-center gap-2 mt-2'>
                                    <strong>Width: </strong>
                                    <p>43-44 inch (approx)</p>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col-5'>
                                <div className='d-flex align-items-center gap-2 mt-2 justify-content-center'>
                                    <strong>COD:  </strong>
                                    <p className='p-0'>Not Available</p>
                                </div>
                            </div>
                            <div className='col-7'>
                                <div className='d-flex align-items-center gap-2 mt-2 justify-content-center'>
                                    <strong>Replacement:  </strong>
                                    <p className='p-0'>No</p>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </CommonLayout>
    )
}

export default ProductPreview