import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  coupon: "",
};

export const couponSlice = createSlice({
  name: "couponCode",
  initialState,
  reducers: {
    getCoupon: (state, action) => {
      state.coupon = action.payload;
    },
  },
});

export const { getCoupon } = couponSlice.actions;
export default couponSlice.reducer; //export reducer to store.js file
