import React from 'react'
import CommonLayout from '../layout/CommonLayout'
import UseHeader from '../components/UserHeader'
import cart1 from "../assets/images/cart1.png";
import cart2 from "../assets/images/cart2.png";


function OrderConfirm() {
    return (
        <>
            <CommonLayout>
                <UseHeader />
                <div className='order-cnf mb-3'>
                    <p>Order Number : <span className='font-weight-bold'>HELLO827330</span></p>
                    <p>Order Status: New : <span className="text-success">New</span></p>
                    <p>Order Date : <span>Wednesday, 12 July 2024, 9:14 am</span></p>
                    <p>Customer Name : <span>Raana Chatterjee</span></p>
                    <p>Customer Address : <span>H1-151, Assotech Springfields Apartment, Sector Zeta 1, Greater Noida</span></p>
                    <p>Customer Email :  <span>raana1981@gmail.com</span></p>
                    <p>Customer Mobile :  <span>9971636690</span></p>
                </div>

                <div className='order-cnf'>
                    <div className="d-flex">
                        <div className="col-3">
                            <img
                                className='rounded'
                                width={84}
                                height={93}
                                src={cart1 || "https://via.placeholder.com/84x93"}

                            />
                        </div>
                        <div className="col-9">
                            <div className="col-12 d-flex justify-content-between px-2">
                                <div style={{ display: "inline-block" }}>
                                    <p
                                        style={{
                                            marginBottom: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Imaaya Ajrakh Sari
                                    </p>
                                    <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                                        (Variant Name: Value)
                                    </p>
                                    <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                                        Rs. 7,500
                                    </p>
                                    <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                                        Qty. 4
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex">
                        <div className="col-3">
                            <img
                                className='rounded'
                                width={84}
                                height={93}
                                src={cart2 || "https://via.placeholder.com/84x93"}

                            />
                        </div>
                        <div className="col-9">
                            <div className="col-12 d-flex justify-content-between px-2">
                                <div style={{ display: "inline-block" }}>
                                    <p
                                        style={{
                                            marginBottom: "0px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Imaaya Ajrakh Sari
                                    </p>
                                    <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                                        (Variant Name: Value)
                                    </p>
                                    <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                                        Rs. 7,500
                                    </p>
                                    <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                                        Qty. 4
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <p className='pb-2'>Payment Type : <span>Prepaid</span></p>
                    <p>Order Total : <span>8270</span></p>
                </div>
                <div className='my-4 mx-3 order-total'>
                    <h2>Shipment Details</h2>
                    <hr />
                    <div>
                        <div className='row'>
                            <div className='col-6 left-header-layout'>
                                <p>Courier </p>
                            </div>
                            <div className='col-6 right-header-layout'>
                                <p>Delhivery</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6 left-header-layout'>
                                <p>AWB Number</p>
                            </div>
                            <div className='col-6 right-header-layout'>
                                <p>6468263834</p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between bg-white p-3">
                            <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: '600', color: "rgba(51, 113, 165, 1)" }}>
                                Click here to track the shipment
                            </p>
                            <img src="/static/media/checkCouponArrow.9d6c13e63a766e207ee26a63c5ede1d4.svg" alt="" />
                        </div>
                        <button className='pdf-download'>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 8.00195C18.175 8.01395 19.353 8.11095 20.121 8.87895C21 9.75795 21 11.172 21 14V15C21 17.829 21 19.243 20.121 20.122C19.243 21 17.828 21 15 21H7C4.172 21 2.757 21 1.879 20.122C1 19.242 1 17.829 1 15V14C1 11.172 1 9.75795 1.879 8.87895C2.647 8.11095 3.825 8.01395 6 8.00195" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M11 1V14M11 14L8 10.5M11 14L14 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            PDF Download
                        </button>
                    </div>
                </div>
            </CommonLayout>
        </>
    )
}

export default OrderConfirm