import React from "react";

function ProceedToBuyModal() {
  return (
    <div
      className="modal fade"
      id="proceedtobuy"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ borderRadius: "20px" }}>
          <div className="modal-body" style={{textAlign:"center",padding:"24px"}}>
            <p
              style={{
                textAlign: "center",
                color: "#666666",
                fontWeight: "400",
                fontSize: "15px",
              }}
            >
              We are not taking orders at this moment. Sorry for the
              inconvenience caused!
            </p>
            <button
              type="button"
              //   className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                backgroundColor: "#3371A5",
                borderRadius: "23px",
                padding: "17px 48px",
                border: "none",

              }}
            >
              <span style={{ color: "#fff", fontWeight: "500" }}>OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProceedToBuyModal;
