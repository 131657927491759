import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";

const rootElement = document.getElementById("root");
const app = (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// Use hydrateRoot if the app has pre-rendered HTML, otherwise createRoot
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, app); // React 18 hydrate
} else {
  const root = ReactDOM.createRoot(rootElement); // React 18 render
  root.render(app);
}

reportWebVitals();
