import React, { useState } from "react";
import HelloTabContent from "./HelloTabContent";
import StoreTabContent from "./StoreTabContent";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTab } from "../reducer/controlTab";


function ProfileTab() {
  const { userId } = useParams();
  const currTab = useSelector((state)=>state.controlTab?.tab)
  const dispatch=useDispatch()
  // const [tabState, setTabState] = useState(1);
  const token = localStorage.getItem('token');
  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <div className="wrapper-tab ">
          <li
            className="nav-item"
            // onClick={() => setTabState(1)}
            onClick={()=>dispatch(setTab(1))}
            role="presentation"
          >
            <button
              className={currTab === 1 ? "nav-link active ":"nav-link "}
              id="hello-tab"
              data-bs-toggle="tab"
              data-bs-target="#hello-tab-pane"
              type="button"
              role="tab"
              aria-controls="hello-tab-pane"
              aria-selected="false"
            >
              Hello
            </button>
          </li>
          <li
            className="nav-item"
            // onClick={() => setTabState(2)}
            onClick={()=>dispatch(setTab(2))}
            role="presentation"
          >
            <button
              className={currTab === 2 ? "nav-link active ":"nav-link "}
              id="store-tab"
              data-bs-toggle="tab"
              data-bs-target="#store-tab-pane"
              type="button"
              role="tab"
              aria-controls="store-tab-pane"
              aria-selected="true"
            >
              Store
            </button>
          </li>
          <div
            className={
              currTab === 1
                ? "activeBackground active"
                : "activeBackground inactive"
            }
          ></div>
        </div>
        {currTab === 2 && (
          
          <Link to={token ? `/${userId}/myaccount` : `/${userId}/login`} className="position-absolute user">
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 1C6.59625 1 1 6.59625 1 13.5C1 20.4037 6.59625 26 13.5 26C20.4037 26 26 20.4037 26 13.5C26 6.59625 20.4037 1 13.5 1Z"
                stroke="#666666"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.83936 21.4315C3.83936 21.4315 6.62561 17.874 13.5006 17.874C20.3756 17.874 23.1631 21.4315 23.1631 21.4315M13.5006 13.499C14.4952 13.499 15.449 13.1039 16.1523 12.4007C16.8555 11.6974 17.2506 10.7436 17.2506 9.74902C17.2506 8.75446 16.8555 7.80063 16.1523 7.09737C15.449 6.39411 14.4952 5.99902 13.5006 5.99902C12.506 5.99902 11.5522 6.39411 10.849 7.09737C10.1457 7.80063 9.75061 8.75446 9.75061 9.74902C9.75061 10.7436 10.1457 11.6974 10.849 12.4007C11.5522 13.1039 12.506 13.499 13.5006 13.499Z"
                stroke="#666666"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        )}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className={currTab === 1 ? "tab-pane fade show active":"tab-pane fade "}
          id="hello-tab-pane"
          role="tabpanel"
          aria-labelledby="hello-tab"
          tabIndex="0"
        >
          <HelloTabContent />
        </div>
        <div
          className={currTab === 2 ? "tab-pane fade show active":"tab-pane fade"}
          id="store-tab-pane"
          role="tabpanel"
          aria-labelledby="store-tab"
          tabIndex="0"
        >
          <StoreTabContent />
        </div>
      </div>
    </>
  );
}

export default ProfileTab;
