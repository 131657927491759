import React from "react";

export default function CommonButton({ name, width = "100%", onClick }) {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: "#3371A5",
        color: "#fff",
        border: "none",
        borderRadius: "10px",
        padding: "14px",
        width: width,
        marginTop: "30px",
        fontWeight: "600",
      }}
    >
      {name}
    </button>
  );
}
