import React, { useEffect, useState } from 'react';
import CommonLayout from '../layout/CommonLayout';
import BackClick from '../components/BackClick';
import CommonButton from '../components/CommonButton';
import { useNavigate, useParams } from 'react-router-dom';

function Login() {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate(`/${userId}/myaccount`);
        }else{
            navigate(`/${userId}/login`);
        }
    }, [navigate, userId]);
    

    const handleInputChange = (event) => {
        setMobileNumber(event.target.value);
        if (error) setError('');
    };

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (mobileNumber.length !== 10 || !/^\d{10}$/.test(mobileNumber)) {
            setError('Please enter a valid 10-digit number');
            return;
        }

        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}customer/login`;
        const payload = {
            mobile: `91${mobileNumber}`
        };

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            const result = await response.json();

            if (response.ok) {
                console.log("OTP sent successfully:", result);
                navigate(`/${userId}/otp-verification`, { state: { mobileNumber } });
            } else {
                console.error("Error sending OTP:", result);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <CommonLayout>
            <BackClick />
            <div className='form-fild pt-3 p-3 h-100vh'>
                <form onSubmit={handleButtonClick}>
                    <div className='position-relative'>
                        <div className='wrap-fildset'>
                            <input
                                className='number-fild'
                                type='number'
                                placeholder='Enter your mobile number'
                                value={mobileNumber}
                                onChange={handleInputChange}
                            />
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="..." fill="#858585" />
                            </svg>
                        </div>
                        <label className='floatlavel'>Mobile Number</label>
                    </div>
                    {error && <p className='error-message pt-1 text-danger'>{error}</p>}

                    <CommonButton
                        type="submit" 
                        name={"Generate OTP"}
                    />
                </form>
            </div>
        </CommonLayout>
    );
}

export default Login;
