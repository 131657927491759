import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  particularId: "",
};

export const productListSlice = createSlice({
  name: "productList",
  initialState,
  reducers: {
    getList: (state, action) => {
      state.products = action.payload;
    },
    setPId: (state, action) => {
      state.particularId = action.payload;
    },
  },
});

export const { getList,setPId } = productListSlice.actions;
export default productListSlice.reducer; //export reducer to store.js file
