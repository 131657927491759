import React from "react";
import CommonLayout from "../layout/CommonLayout";
import tick from "../assets/images/icons/tickicon.svg";
import BackClick from "../components/BackClick";
import OrderHistory from "../components/orderSuccessfullElement/OrderHistory";
import CommonButton from "../components/CommonButton";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function OrderSuccessfull() {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userid");
  const { neworderId, paymentType, orderDateTime, orderAmount } =
    location.state || {};

    const handleClick = () => {
      navigate(`/${userId}`);
    };
  return (
    <CommonLayout>
      <BackClick />
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "18px",
        }}
      >
        <img height={"70px"} width={"70px"} src={tick} alt="" />
        <p
          style={{
            fontWeight: "800",
            fontSize: "20",
            color: "#000",
            marginBottom: "0px",
          }}
        >
          Order placed successfully
        </p>
        <div style={{ textAlign: "center" }}>
          <OrderHistory detail={"Order Number"} value={neworderId} />
          <OrderHistory detail={"Payment Type"} value={paymentType} />
          <OrderHistory detail={"Order Date & Time"} value={orderDateTime} />
          <OrderHistory detail={"Order Amount"} value={`Rs. ${orderAmount}`} />
        </div>
        <CommonButton
          name={"Let’s Shop More"}
          width={"80%"}
          onClick={handleClick}
        />
      </div>
    </CommonLayout>
  );
}
