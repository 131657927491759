import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterVariant: { firstVariant: "", secondVariant: "" },
};

export const variantFilterSlice = createSlice({
  name: "variantFilter",
  initialState,
  reducers: {
    setFirstVariant: (state, action) => {
      state.filterVariant.firstVariant = action.payload;
    },
    setSecondVariant: (state, action) => {
      state.filterVariant.secondVariant = action.payload;
    },
  },
});

export const { setFirstVariant, setSecondVariant } = variantFilterSlice.actions;
export default variantFilterSlice.reducer;
