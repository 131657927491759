import React from 'react'
import productList from "../assets/Data/productList.json";

function CollectionPage() {
    return (
        <>
            <div className='header-layout'>
                <div className='row'>
                    <div className='col-6 left-header-layout'>
                        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.625 8.75C19.625 9.13672 19.2812 9.4375 18.8945 9.4375H2.69531L8.71094 15.8828C8.96875 16.1406 8.96875 16.5703 8.66797 16.8281C8.53906 16.957 8.36719 17 8.19531 17C7.98047 17 7.80859 16.957 7.67969 16.7852L0.546875 9.22266C0.289062 8.96484 0.289062 8.57812 0.546875 8.32031L7.67969 0.757812C7.9375 0.457031 8.36719 0.457031 8.66797 0.714844C8.96875 0.972656 8.96875 1.40234 8.71094 1.66016L2.69531 8.0625H18.8945C19.2812 8.0625 19.625 8.40625 19.625 8.75Z" fill="#666666" />
                        </svg>
                        <h6>MyAntarang</h6>
                    </div>
                    <div className='col-6 right-header-layout'>
                        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 19C8.55228 19 9 18.5523 9 18C9 17.4477 8.55228 17 8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M19.5 19C19.7761 19 20 18.5523 20 18C20 17.4477 19.7761 17 19.5 17C19.2239 17 19 17.4477 19 18C19 18.5523 19.2239 19 19.5 19Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 1H4.30435L6.78261 15H20" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.60882 12H20.1496C20.2426 12.0001 20.3328 11.9641 20.4047 11.8981C20.4767 11.8322 20.526 11.7404 20.5442 11.6383L21.9922 3.53831C22.0039 3.473 22.0024 3.4056 21.988 3.34097C21.9736 3.27634 21.9465 3.21611 21.9088 3.16461C21.871 3.11311 21.8235 3.07163 21.7697 3.04316C21.7159 3.0147 21.657 2.99995 21.5975 3H6" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 1C5.0293 1 1 5.0293 1 10C1 14.9707 5.0293 19 10 19C14.9707 19 19 14.9707 19 10C19 5.0293 14.9707 1 10 1Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.04395 15.711C3.04395 15.711 5.05005 13.1496 10 13.1496C14.95 13.1496 16.957 15.711 16.957 15.711M10 9.99961C10.7161 9.99961 11.4029 9.71515 11.9092 9.2088C12.4156 8.70245 12.7 8.01569 12.7 7.29961C12.7 6.58352 12.4156 5.89677 11.9092 5.39042C11.4029 4.88407 10.7161 4.59961 10 4.59961C9.28396 4.59961 8.5972 4.88407 8.09086 5.39042C7.58451 5.89677 7.30005 6.58352 7.30005 7.29961C7.30005 8.01569 7.58451 8.70245 8.09086 9.2088C8.5972 9.71515 9.28396 9.99961 10 9.99961Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className='collection-title'>
                <h4>Spring Summer 2024 Collection</h4>
            </div>
            <div className="d-flex justify-content-between  ProductItemContainer">
                {productList &&
                    productList.map((item) => (
                        <div className="ProductItem">
                            {item.status !== null && (
                                <div className="ItemStatus">
                                    <span
                                        style={
                                            item.status === "SALE"
                                                ? { backgroundColor: "#3371a5" }
                                                : item.status === "NEW" && {
                                                    backgroundColor: " #F05323",
                                                }
                                        }
                                    >
                                        {item.status}
                                    </span>
                                </div>
                            )}
                            <img src={item.imgSrc} alt="" />
                            <div className="viewAll">
                                <img src="/Images/Icons/Viewall.png" alt="" />
                                <i
                                    className="fa-solid fa-ellipsis-vertical "
                                    data-bs-toggle="modal"
                                    data-bs-target="#socialshare"
                                    style={{ color: "#fff" }}
                                ></i>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="hello-store-brn" id='hello-store-brn'>
                <h6>View Full Catalogue</h6>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.09066 4.67545C10.3817 4.67545 11.4284 3.62882 11.4284 2.33773C11.4284 1.04664 10.3817 0 9.09066 0C7.79956 0 6.75293 1.04664 6.75293 2.33773C6.75293 3.62882 7.79956 4.67545 9.09066 4.67545Z" fill="white"/>
                <path d="M2.33773 4.67545C3.62882 4.67545 4.67545 3.62882 4.67545 2.33773C4.67545 1.04664 3.62882 0 2.33773 0C1.04664 0 0 1.04664 0 2.33773C0 3.62882 1.04664 4.67545 2.33773 4.67545Z" fill="white"/>
                <path d="M9.09066 11.4284C10.3817 11.4284 11.4284 10.3817 11.4284 9.09066C11.4284 7.79956 10.3817 6.75293 9.09066 6.75293C7.79956 6.75293 6.75293 7.79956 6.75293 9.09066C6.75293 10.3817 7.79956 11.4284 9.09066 11.4284Z" fill="white"/>
                <path d="M2.33773 11.4284C3.62882 11.4284 4.67545 10.3817 4.67545 9.09066C4.67545 7.79956 3.62882 6.75293 2.33773 6.75293C1.04664 6.75293 0 7.79956 0 9.09066C0 10.3817 1.04664 11.4284 2.33773 11.4284Z" fill="white"/>
                </svg>
            </div>
        </>
    )
}

export default CollectionPage