import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addressList: [],
  
};

export const addressSlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.addressList = action.payload;
    }
  },
});

export const { setAddress } = addressSlice.actions;
export default addressSlice.reducer; //export reducer to store.js file
