import { createSlice } from "@reduxjs/toolkit";

const initialState={
    manageState:false
}

export const ManageModalStateSlice = createSlice({
    name: 'modalState',
    initialState,
    reducers:{
        getModalState:(state,action)=>{
            state.manageState=action.payload
        }
    }
})

export const {getModalState}=ManageModalStateSlice.actions;
export default ManageModalStateSlice.reducer;  //export reducer to store.js file