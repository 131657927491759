import React, { useEffect, useState } from 'react'
import CommonLayout from '../layout/CommonLayout'
import UseHeader from '../components/UserHeader'
import OrderHistoryLayoutOne from '../components/OrderHistoryLayoutOne'

function OrderHistory() {

    const [orderhistory, setOrderhistory] = useState();
    useEffect(() => {
        const Orderhistory = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}customer/get-orders`, {
                    method: 'GET',
                    headers: {
                        "token": token,
                    },
                });
                const data = await response.json();
                setOrderhistory(data?.orders);
            } catch (error) {
                console.error('Error fetching order history:', error);
            }
        };
        Orderhistory();
    }, []);

    return (
        <>
            <CommonLayout>
                <UseHeader />
                {orderhistory && orderhistory.length > 0 ? (
                    orderhistory
                    .slice()
                    .reverse()
                    .map((item, i) => (
                        <OrderHistoryLayoutOne
                            key={i}
                            ordernumber={item.orderNumber}
                            status={item.orderStatus}
                            date={item.orderDate}
                            amount={item.orderAmount}
                        />
                    ))
                ) : (
                    <p
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "100vh" }}
            >
              <div className="d-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100px"
                  height="100px"
                  viewBox="0 0 24 24"
                >
                  <path
                    style={{ fill: "#3371a5" }}
                    d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm1.336-5l1.977-7h-16.813l2.938 7h11.898zm4.969-10l-3.432 12h-12.597l.839 2h13.239l3.474-12h1.929l.743-2h-4.195z"
                  />
                </svg>
                <p>History is empty</p>
              </div>
            </p>
                )}
            </CommonLayout>
        </>
    )
}

export default OrderHistory