import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setFirstVariant,
  setSecondVariant,
} from "../reducer/variantFilterSlice";

function VariantList({ title, list, isLoading, indx }) {
  const dispatch = useDispatch();
  const [newFilter, setNewFilter] = useState({
    firstVariant: "",
    secondVariant: "",
  });

  useEffect(() => {
    setNewFilter({
      firstVariant: indx === 0 ? list[0] : "",
      secondVariant: indx === 1 ? list[0] : "",
    });
  }, []);
  if (indx === 0) {
    dispatch(setFirstVariant(newFilter.firstVariant));
  } else {
    dispatch(setSecondVariant(newFilter.secondVariant));
  }

  const handleVariantClick = (variant) => {
    const updatedFilter =
      indx === 0
        ? { ...newFilter, firstVariant: variant }
        : { ...newFilter, secondVariant: variant };

    setNewFilter(updatedFilter);
    if (indx === 0) {
      dispatch(setFirstVariant(updatedFilter.firstVariant));
    } else {
      dispatch(setSecondVariant(updatedFilter.secondVariant));
    }
  };

  return (
    <div className="additionaldetails">
      <div className="Variant-title">
        <h4>{title}</h4>
      </div>
      <div className="Variant-group">
        {isLoading ? (
          <h6>Loading....</h6>
        ) : (
          list?.map((item) => (
            <div
              style={{ position: "relative" }}
              key={item}
              onClick={() => handleVariantClick(item)}
              className={`Variant-item ${
                newFilter[indx === 0 ? "firstVariant" : "secondVariant"] ===
                item
                  ? "active"
                  : ""
              }`}
            >
              <input
                type="radio"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  top: "0px",
                  left: "0px",
                  display: "none",
                }}
                name={`variant-${indx}`} // ensures only one can be checked within this group
                checked={
                  newFilter[indx === 0 ? "firstVariant" : "secondVariant"] ===
                  item
                }
                readOnly
              />
              {item}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default React.memo(VariantList);
