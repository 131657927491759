import React from "react";

function OrderHistory({detail,value}) {
  return (
    <p style={{ color: "#666666", fontSize: "15px",marginBottom:"0px" }}>
      {detail}: <span style={{ color: "#000" }}>{value}</span>
    </p>
  );
}

export default OrderHistory;
