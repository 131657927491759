import React from 'react';
import useicon from '../assets/images/icons/user.svg';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTab } from "../reducer/controlTab";

function UseHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const userNamedyn = useSelector((state) => state.fetchUser?.user?.name);
  const location = useLocation();
  const orderhistorypath = `/${userId}/order-history`;
  const orderconfirm = `/${userId}/order-confirm`;
  const productpreview = `/${userId}/product-preview`;
  const userMobnumber = localStorage.getItem('usermob');

  const handleBackClick = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(`/${userId}`);
    }
    dispatch(setTab(2));
  };

  const handelicon = () => {
    navigate(`/${userId}/myaccount`);
  }
  const handelstorenamelink = () => {
    navigate(`/${userId}`);
  }

  return (
    <>
      <div className="header-layout">
        <div className="row px-2">
          <div className="col-6 left-header-layout">

            {location.pathname === orderhistorypath || location.pathname === orderconfirm || location.pathname === productpreview ?
              <>
                <svg
                  onClick={handleBackClick}
                  style={{ cursor: "pointer" }}
                  width="20"
                  height="17"
                  viewBox="0 0 20 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.625 8.75C19.625 9.13672 19.2812 9.4375 18.8945 9.4375H2.69531L8.71094 15.8828C8.96875 16.1406 8.96875 16.5703 8.66797 16.8281C8.53906 16.957 8.36719 17 8.19531 17C7.98047 17 7.80859 16.957 7.67969 16.7852L0.546875 9.22266C0.289062 8.96484 0.289062 8.57812 0.546875 8.32031L7.67969 0.757812C7.9375 0.457031 8.36719 0.457031 8.66797 0.714844C8.96875 0.972656 8.96875 1.40234 8.71094 1.66016L2.69531 8.0625H18.8945C19.2812 8.0625 19.625 8.40625 19.625 8.75Z"
                    fill="#666666"
                  />
                </svg>
                <h6
                  style={{ cursor: 'pointer' }}
                  onClick={handelstorenamelink}
                  className="usertitle"
                >
                  {userNamedyn}
                </h6>
              </>
              :

              <>
                <svg
                  onClick={handleBackClick}
                  style={{ cursor: "pointer" }}
                  width="20"
                  height="17"
                  viewBox="0 0 20 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.625 8.75C19.625 9.13672 19.2812 9.4375 18.8945 9.4375H2.69531L8.71094 15.8828C8.96875 16.1406 8.96875 16.5703 8.66797 16.8281C8.53906 16.957 8.36719 17 8.19531 17C7.98047 17 7.80859 16.957 7.67969 16.7852L0.546875 9.22266C0.289062 8.96484 0.289062 8.57812 0.546875 8.32031L7.67969 0.757812C7.9375 0.457031 8.36719 0.457031 8.66797 0.714844C8.96875 0.972656 8.96875 1.40234 8.71094 1.66016L2.69531 8.0625H18.8945C19.2812 8.0625 19.625 8.40625 19.625 8.75Z"
                    fill="#666666"
                  />
                </svg>
                <h6
                  style={{ cursor: 'pointer'}}
                  onClick={handelstorenamelink}
                  className="usertitle"
                >
                  +91 {userMobnumber}
                </h6>
              </>
            }
          </div>
          <div className="col-6 right-header-layout">
            {useicon ? (
              <img src={useicon} alt="User Icon" style={{ width: "30px", height: "30px", cursor: 'pointer' }} onClick={handelicon} />
            ) : (
              "No usericon"
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UseHeader;
