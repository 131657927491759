import { createSlice } from "@reduxjs/toolkit";

const initialState={
    sizeChart:[]
}

export const commonValuesSlice = createSlice({
    name: 'commonValues',
    initialState,
    reducers:{
        getSizeChart:(state,action)=>{
            state.sizeChart=action.payload
        }
    }
})

export const {getSizeChart}=commonValuesSlice.actions;
export default commonValuesSlice.reducer;  //export reducer to store.js file