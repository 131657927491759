import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function CommonModal({show, handleClose,title,content}) {
  return (
   <>
    <Modal show={show} onHide={handleClose} >
        <Modal.Header  closeButton>
          <Modal.Title >{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {content}
        </Modal.Body>
      </Modal>
   </>
  )
}
