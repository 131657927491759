import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonLayout from "../layout/CommonLayout";
import BackClick from "../components/BackClick";
import SingleCartProduct from "../components/cartelements/SingleCartProduct";
import coupon from "../assets/images/icons/applyCouponIcon.svg";
import arrow from "../assets/images/icons/checkCouponArrow.svg";
import deleteCoupon from "../assets/images/icons/deleteCouponIcon.svg";
import tick from "../assets/images/icons/COUPONTICK.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCoupon } from "../reducer/couponSlice";


function Cart() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [cartitems, setCartitems] = useState([]);
  const currentCoupon = useSelector((state) => state.couponControl?.coupon);
  const dispatch = useDispatch();
  const storedCartItems = localStorage.getItem("cart");
  const cartData = storedCartItems ? JSON.parse(storedCartItems) : [];
  const token = localStorage.getItem('token');



  useEffect(() => {
    const storedCartItems = localStorage.getItem("cart");
    const cartData = storedCartItems ? JSON.parse(storedCartItems) : [];
    setCartitems(cartData);
  }, []);

  const delcartitem = (index) => {
    const updatedCartItems = cartitems.filter((_, i) => i !== index);
    setCartitems(updatedCartItems);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedCartItems = [...cartitems];
    updatedCartItems[index] = {
      ...updatedCartItems[index],
      quantity: newQuantity,
    };
    setCartitems(updatedCartItems);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
  };

  const calculateTotal = () => {
    const totalCartValue = cartitems.reduce((total, item) => {
      const itemQuantity = item.quantity || 1;
      return total + item.price * itemQuantity;
    }, 0);
    const shippingCharges = cartData.length == 0 ? 0 : 120;
    const paymentGatewayCharges = cartData.length == 0 ? 0 : 50;
    const couponDiscount = currentCoupon !== "" ? 10 : 0;
    return (
      totalCartValue + shippingCharges + paymentGatewayCharges - couponDiscount
    );
  };
  const totalAmount = calculateTotal();
  const couponDiscountAmount = currentCoupon ? 10 : 0;
  const shippingCharges = cartData.length == 0 ? 0 : 120;
  const paymentGatewayCharges = cartData.length == 0 ? 0 : 50;


  const products = cartData.map(item => ({
    productId: item.id,
    variantId: item.variantId || '',
    quantity: item.quantity,
    amount: item.price * item.quantity
  }));





  const handelprocesstobuy = () => {
    if (token) {
      navigate(`/${userId}/add-payment-address/`, {
        state: {
          totalAmount,
          products: cartitems
        }
      });
    } else {
      navigate(`/${userId}/login`);
    }

  }
  return (
    <CommonLayout>
      <BackClick />
      <div
        style={{
          backgroundColor: "#fff",
          marginTop: "5px",
          padding: "10px",
          minHeight: "92vh",
          height: "fit-content",
          paddingBottom: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxHeight: "400px",
            overflowY: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >

          {cartData.length > 0 ? (
            cartData.map((item, index) => {
              return (
                <SingleCartProduct
                  key={index}
                  src={item.image}
                  variantname={"variant"}
                  value={item?.variant?.firstVariant?.firstVariant}
                  productName={item.title}
                  price={item.price}
                  newquantity={item.quantity}
                  delcartitem={() => delcartitem(index)}
                  onQuantityChange={(newQuantity) =>
                    handleQuantityChange(index, newQuantity)
                  }
                />
              );
            })
          ) : (
            <p
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "100vh" }}
            >
              <div className="d-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100px"
                  height="100px"
                  viewBox="0 0 24 24"
                >
                  <path
                    style={{ fill: "#3371a5" }}
                    d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm1.336-5l1.977-7h-16.813l2.938 7h11.898zm4.969-10l-3.432 12h-12.597l.839 2h13.239l3.474-12h1.929l.743-2h-4.195z"
                  />
                </svg>
                <p>Cart is empty</p>
              </div>
            </p>
          )}
          <style jsx>{`
            div::-webkit-scrollbar {
              display: none;
            }
          `}</style>
        </div>

        {cartData.length > 0 ? (
          <>
            <hr />
            <h5>Other Charges</h5>
            <hr />
            <div>
              <div className="d-flex justify-content-between">
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "14px",
                    color: "#333",
                  }}
                >
                  Shipping Charges
                </p>
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "14px",
                    color: "#333",
                  }}
                >
                  Rs. {shippingCharges}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "14px",
                    color: "#333",
                  }}
                >
                  Payment Gateway Charges
                </p>
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "14px",
                    color: "#333",
                  }}
                >
                  Rs. {paymentGatewayCharges}
                </p>
              </div>
              {currentCoupon !== "" && (
                <div className="d-flex justify-content-between">
                  <p
                    style={{
                      marginBottom: "0px",
                      fontSize: "14px",
                      color: "#333",
                    }}
                  >
                    Coupon discount
                  </p>
                  <p
                    style={{
                      marginBottom: "0px",
                      fontSize: "14px",
                      color: "#1DD75B",
                    }}
                  >
                    - Rs. {couponDiscountAmount}
                  </p>
                </div>
              )}
            </div>
            <hr />
            <div
              style={{
                borderRadius: "10px",
                padding: "10px",
                backgroundColor: "#F0F1F6",
                position: "relative",
              }}
              data-bs-toggle={currentCoupon === "" ? "modal" : ""}
              data-bs-target={currentCoupon === "" ? "#applycoupon" : ""}
            >
              <img src={coupon} alt="" />
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#3371A5",
                  marginLeft: "16px",
                }}
              >
                {currentCoupon === "" ? "Apply discount coupon" : currentCoupon}
              </span>
              {currentCoupon === "" ? (
                <img
                  style={{ position: "absolute", top: "33%", right: "8px" }}
                  src={arrow}
                  alt=""
                />
              ) : (
                <img
                  style={{ position: "absolute", top: "23%", right: "8px" }}
                  src={deleteCoupon}
                  alt=""
                  onClick={() => dispatch(getCoupon(""))}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  color: "#333",
                  marginBottom: "0px",
                  fontWeight: "600",
                }}
              >
                Total Bill (incl. taxes, charges):
              </p>
              <p
                style={{
                  marginBottom: "0px",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#3371A5",
                }}
              >
                Rs. {totalAmount}
              </p>
            </div>
            {currentCoupon !== "" && (
              <div
                style={{
                  marginTop: "20px",
                  fontSize: "14px",
                  color: "#1DD75B",
                }}
              >
                <img src={tick} alt="" />
                <span style={{ marginLeft: "10px" }}>
                  You saved Rs. {couponDiscountAmount} with this order
                </span>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="bg-white">
        {cartData && cartData?.length > 0 ? (
          <button
          id="CTAbtn"
          className="btn"
          style={{
            display: "flex",
            width: "90%",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: "#3371A5",
            color: "#fff",
            alignItems: "center",
            justifyContent: "center",
            bottom: "14px",
            left: "0",
            right: "0",
            marginLeft: "auto",
            marginRight: "auto",
            gap: "14px",
          }}

          onClick={() => { handelprocesstobuy() }}
          disabled={cartData?.length === 0}
        >
          <p style={{ marginBottom: "0px" }}>
            Proceed to Buy
          </p>
        </button>
        ) : (
          ""
        )}
         
          
      </div>
    </CommonLayout >
  );
}

export default Cart;
