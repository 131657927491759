import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import ShareButton from "./WebShareBtn";

function ShareModal() {
  const { userId, productId } = useParams();

  // const pathLink = location.pathname;
  const state = useSelector((state) => state.manageModal?.manageState);
  const phoneNumber = useSelector((state) => state.fetchUser?.user?.phone);
  const insta = useSelector((state) =>
    state.fetchUser?.user?.services?.find((item) => item.title === "Instagram")
  );
  const pId = useSelector((state) => state.productslst?.particularId);
  const staticUrl = `${baseUrl}productdetails/${userId && userId}/${
    pId ? pId : productId
  }`;

  const encodedMessage = encodeURIComponent(`${staticUrl}`);

  // const handleShare = async (url) => {
  //   if (navigator.share) {
  //     try {
  //       await navigator.share({
  //         // title: "Check out this website!",
  //         // text: "I found this awesome site, you should check it out!",
  //         url: url,
  //       });
  //       console.log("Successfully shared");
  //     } catch (error) {
  //       console.error("Error sharing:", error);
  //     }
  //   } else {
  //     console.error("Web Share API is not supported in this browser.");
  //   }
  // };

  return (
    <div
      className="modal fade"
      id="socialshare"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="social-group">
              {state === false ? (
                <>
                  <ShareButton url={staticUrl && staticUrl}>
                    <div className="social-item">
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.125 3.5625C20.125 5.49609 18.5781 7 16.6875 7C15.6992 7 14.7969 6.61328 14.1523 5.92578L7.66406 9.19141C7.70703 9.36328 7.75 9.57812 7.75 9.75C7.75 9.96484 7.70703 10.1797 7.66406 10.3516L14.1523 13.5742C14.7969 12.9297 15.6992 12.5 16.6875 12.5C18.5781 12.5 20.125 14.0469 20.125 15.9375C20.125 17.8711 18.5781 19.375 16.6875 19.375C14.7539 19.375 13.25 17.8711 13.25 15.9375C13.25 15.5508 13.293 15.1211 13.4219 14.7773L7.19141 11.6406C6.54688 12.5859 5.51562 13.1875 4.3125 13.1875C2.37891 13.1875 0.875 11.6836 0.875 9.75C0.875 7.85938 2.37891 6.3125 4.3125 6.3125C5.51562 6.3125 6.54688 6.95703 7.19141 7.90234L13.4219 4.76562C13.293 4.37891 13.25 3.99219 13.25 3.5625C13.25 1.67188 14.7539 0.125 16.6875 0.125C18.5781 0.125 20.125 1.67188 20.125 3.5625ZM4.26953 11.8125C5.42969 11.8125 6.33203 10.9102 6.33203 9.75C6.33203 8.63281 5.42969 7.6875 4.26953 7.6875C3.15234 7.6875 2.20703 8.63281 2.20703 9.75C2.20703 10.9102 3.15234 11.8125 4.26953 11.8125ZM16.6875 1.5C15.5273 1.5 14.625 2.44531 14.625 3.5625C14.625 4.72266 15.5273 5.625 16.6875 5.625C17.8047 5.625 18.75 4.72266 18.75 3.5625C18.75 2.44531 17.8047 1.5 16.6875 1.5ZM16.6875 18C17.8047 18 18.75 17.0977 18.75 15.9375C18.75 14.8203 17.8047 13.875 16.6875 13.875C15.5273 13.875 14.625 14.8203 14.625 15.9375C14.625 17.0977 15.5273 18 16.6875 18Z"
                          fill="#3371A5"
                        />
                      </svg>

                      <p>Share with your friends</p>
                    </div>
                  </ShareButton>
                  <Link
                    to={insta?.content}
                    className="social-item"
                    style={{ textDecoration: "none" }}
                  >
                    <img src="../../assets/images/insta.png" alt="" />
                    <p>Chat with Seller on Instagram</p>
                  </Link>
                  <Link
                    to={`https://wa.me/+91${phoneNumber}?text=${encodedMessage}`}
                    target="_blank"
                    className="social-item"
                    style={{ textDecoration: "none" }}
                  >
                    <img src="../../assets/images/whatsapp.png" alt="" />
                    <p>Chat with Seller on Whatsapp</p>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to={insta?.content}
                    className="social-item"
                    style={{ textDecoration: "none" }}
                  >
                    <img src="../../assets/images/insta.png" alt="" />
                    <p>Chat with Seller on Instagram</p>
                  </Link>
                  <Link
                    to={`https://wa.me/+91${phoneNumber}?text=${encodedMessage}`}
                    className="social-item"
                    style={{ textDecoration: "none" }}
                  >
                    <img src="../../assets/images/whatsapp.png" alt="" />
                    <p>Chat with Seller on Whatsapp</p>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareModal;
