import React, { useEffect } from "react";
import tikIcon from "../../assets/images/icons/tickicon.svg"

function AddToCartModal() {
  useEffect(() => {
    const handleBackdrop = () => {
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    };

    const modalElement = document.getElementById('addCart');
    modalElement.addEventListener('hidden.bs.modal', handleBackdrop);

    return () => {
      modalElement.removeEventListener('hidden.bs.modal', handleBackdrop);
    };
  }, []);
  return (
    <>
      <div
        className="modal fade"
        id="addCart"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ borderRadius: "20px" }}>
            <div class="modal-header border-0 p-0 position-relative">
              <button
                style={{
                  position: 'absolute',
                  right: '15px',
                  top: '10px',
                  zIndex: '9999'
                }}
                type="button"
                className="btn-close border-0"
                data-bs-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true"></span>
              </button>
            </div>
            <div className="modal-body">
              <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                <img src={tikIcon} alt="" />
              </div>
              <div style={{ marginTop: "25px" }}>
                <h4 style={{ textAlign: "center", fontWeight: "800", fontSize: "20px" }}>Item added to cart successfully</h4>
                <p style={{ textAlign: "center", fontSize: "15px", color: "#666" }}>Click on the Cart Icon to view your items and manage your order</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddToCartModal;
