import { createSlice } from "@reduxjs/toolkit";

const initialState={
    user:{}
}

export const fetchUserSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers:{
        getUser:(state,action)=>{
            state.user=action.payload
        }
    }
})

export const {getUser}=fetchUserSlice.actions;
export default fetchUserSlice.reducer;  //export reducer to store.js file