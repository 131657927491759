import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 1,
};

export const controlTab = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const { setTab } = controlTab.actions;
export default controlTab.reducer; //export reducer to store.js file
