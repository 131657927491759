import { configureStore } from "@reduxjs/toolkit";
import productListSlice from "../reducer/productListSlice";
import variantFilterSlice from "../reducer/variantFilterSlice";
import manageModalStateSlice from "../reducer/manageModalStateSlice";
import fetchUserSlice from "../reducer/fetchUserSlice";
import categorySlice from "../reducer/categorySlice";
import commonValuesSlice from "../reducer/commonValuesSlice";
import controlTab from "../reducer/controlTab";
import couponSlice from "../reducer/couponSlice";
import addressSlice from "../reducer/addressSlice";

export const store = configureStore({
  reducer: {
    productslst: productListSlice,
    filterVariantLst: variantFilterSlice,
    manageModal: manageModalStateSlice,
    fetchUser: fetchUserSlice,
    category: categorySlice,
    commonValues: commonValuesSlice,
    controlTab: controlTab,
    couponControl: couponSlice,
    address: addressSlice,
  },
});
