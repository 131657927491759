import React from "react";
import ProfileBanner from '../components/ProfileBanner'
import ProfileInfo from '../components/ProfileInfo'
import ProfileTab from '../components/ProfileTab'
// import { Link } from "react-router-dom";

const ProductList = () => {
  return (
    <>
      <ProfileBanner />
      <ProfileInfo />
      <div className="position-relative">
        <ProfileTab />
      </div>

      
    </>
  );
};

export default ProductList;
