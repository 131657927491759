import React, { useEffect, useState } from 'react';
import ProductDetails from '../pages/ProductDetails';
import BackClick from '../components/BackClick';

function CommonProductDt() {
    const [cartLength, setCartLength] = useState(0);

    const updateCartLength = () => {
        const cart = JSON.parse(localStorage.getItem("cart")) || [];
        setCartLength(cart.length);
    };

    useEffect(() => {
        updateCartLength(); 
        const handleStorageChange = () => {
            updateCartLength();
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []); 

    return (
        <>
            <BackClick showmenu={true} cartLength={cartLength} />
            <ProductDetails cartLength={cartLength} setCartLength={setCartLength} />
        </>
    );
}

export default CommonProductDt;
