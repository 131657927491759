import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductsMapList from "./ProductsMapList";
import { useDispatch, useSelector } from "react-redux";
import { getList } from "../reducer/productListSlice";
import { apiBaseUrl } from "../config";
import axios from "axios";
import { getCategory, getSubcategory } from "../reducer/categorySlice";
import ViewCartButton from "./ViewCartButton";

function StoreTabContent({ cartLength }) {
  const uid = useSelector((state) => state.fetchUser?.user?.id);
  const cart = JSON.parse(localStorage.getItem("cart"));

  const dispatch = useDispatch();
  // const [productCatagory, setProductCatagory] = useState([]);
  const [totalProductLength, setTotalProductLength] = useState(0);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [subcategoryIds, setSubCategoryIds] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const productList = useSelector((state) => state.productslst?.products);
  const productCatagory = useSelector((state) => state.category?.categoryList);
  const subCategory = useSelector((state) => state.category?.subCategoryList);

  const { userId } = useParams();
  // localStorage.setItem("userId", userId);
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}user/get-seller-product-categories/${uid}`);
      const data = await response.json();
      if (data) {
        // setProductCatagory(data);
        dispatch(getCategory(data));
        const allSub = data.flatMap((sub) => sub.subcategories);
        dispatch(getSubcategory(allSub));
      }
      console.log("sub", subCategory);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // const fetchSubCategory = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${apiBaseUrl}user/get-seller-subcategories/${userId}`,
  //       { categoryIds: categoryIds }
  //     );
  //     if (response) {
  //       console.log(response);
  //       setSubCategory(response?.data?.subcategories);
  //     }
  //   } catch (error) {}
  // };

  function handleSubCategoryIdAdd(e, id) {
    if (e.target.checked === true) {
      setSubCategoryIds([...subcategoryIds, id]);
    } else if (e.target.checked === false) {
      const newSubCatIds = subcategoryIds.filter((item) => item !== id);
      setSubCategoryIds([...newSubCatIds]);
    }
  }
  useEffect(() => {
    if (categoryIds.length === 0) {
      setSubCategoryIds([]);
    }
    console.log("subid", subcategoryIds)
  }, [categoryIds]);
  function handleCategorySelect(e, id) {
    if (e.target.checked === true) {
      setCategoryIds([...categoryIds, id]);
    } else if (e.target.checked === false) {
      const newCatIds = categoryIds.filter((item) => item !== id);
      setCategoryIds([...newCatIds]);
    }
  }

  function getSubcategoriesByCategoryIds() {
    // Filter categories that match any of the titles in the provided array
    const matchedCategories = productCatagory.filter((cat) =>
      categoryIds.includes(cat._id)
    );
    const subcategories = matchedCategories.flatMap((cat) => cat.subcategories);

    dispatch(getSubcategory(subcategories));
  }

  useEffect(() => {
    if (categoryIds.length > 0) {
      getSubcategoriesByCategoryIds();
    } else if (categoryIds.length === 0) {
      const allSub = productCatagory?.flatMap((sub) => sub.subcategories);
      dispatch(getSubcategory(allSub));
    }
  }, [categoryIds]);

  const fetchProductList = async () => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}user/filter-seller-products/${uid}`,
        { subcategoryIds: subcategoryIds }
      );
      // const data = await response.json();
      if (response) {
        dispatch(getList(response?.data?.products));

        setTotalProductLength(response?.data?.products?.length);
      }
    } catch (error) {
      console.error("Error fetching Products:", error);
    }
  };

  const fetchProductListByCategory = async () => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}user/filter-seller-products-by-category/${uid}`,
        { categoryIds: categoryIds }
      );
      // const data = await response.json();
      if (response) {
        dispatch(getList(response?.data?.products));

        setTotalProductLength(response?.data?.products?.length);
      }
    } catch (error) {
      console.error("Error fetching Products:", error);
    }
  };

  useEffect(() => {
    if (categoryIds.length > 0) {
      fetchProductListByCategory();
      console.log("cateid", categoryIds);
    }
  }, [categoryIds]);

  function handleFilter() {
    fetchProductList();
  }

  useEffect(() => {
    if (categoryIds.length === 0 && subcategoryIds.length === 0) {
      fetchProductList();
    }
  }, [categoryIds, subcategoryIds]);

  useEffect(() => {
    if (uid !== undefined) {
      fetchCategories();
      fetchProductList();
    }
  }, [uid]);

  return (
    <>
      {productCatagory.length > 0 ? (
        <div className="catagory-list" id="storeCategoryList">
          <ul>
            {productCatagory &&
              (showAllCategories
                ? productCatagory
                : productCatagory.slice(0, 5)
              ).map((item, index) => (
                <li key={index} style={{ position: "relative" }}>
                  <input
                    name={"cat" + index}
                    id={"cat" + index}
                    type="checkbox"
                    value={item.categoryId}
                    onChange={(e) => handleCategorySelect(e, item._id)}
                  />
                  <label htmlFor={"cat" + index} to="">
                    {item.title}
                  </label>
                </li>
              ))}
          </ul>
          {productCatagory && productCatagory.length > 4 && (
            <button
              className="border-0 m-auto d-flex justify-content-center"
              onClick={() => setShowAllCategories(!showAllCategories)}
            >
              {showAllCategories ? (
                <i className="fa-solid fa-angles-up"></i>
              ) : (
                <i className="fa-solid fa-angles-down"></i>
              )}
            </button>
          )}
        </div>
      ) : (
        <p className="text-center"> No Catagory Found</p>
      )}

      <div className="productCount">
        <span>
          {totalProductLength ? totalProductLength : 0} products found
        </span>
        <img
          src="/Images/Icons/filter.png"
          alt="filter"
          data-bs-toggle="offcanvas"
          data-bs-target="#filter"
          aria-controls="filter"
        />
      </div>
      <div className="d-flex justify-content-between  ProductItemContainer" style={{ paddingBottom: "62px" }}>
        {totalProductLength ? (
          <ProductsMapList productList={productList && productList} />
        ) : (
          <p className="text-center d-block w-100"> No Record Found !</p>
        )}
      </div>
      {/* ----------------- uncomment this ------> */}
      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#socialshare">
        social share
      </button> */}

      {/* social share */}
      {/* <ShareModal /> */}

      {/* filter offcanvas */}
      <div
        className="offcanvas offcanvas-bottom"
        tabIndex="-1"
        id="filter"
        aria-labelledby="filter"
      >
        <div className="d-flex justify-content-between filter-action">
          <h4>Filter</h4>
          <button
            data-bs-toggle="offcanvas"
            data-bs-target="#filter"
            aria-controls="filter"
            className="apply-btn"
            onClick={handleFilter}
          >
            Apply
          </button>
        </div>
        <div className="offcanvas-body small">
          <div className="filter-body p-3 w-100">
            <div className="filter-group">
              {subCategory &&
                subCategory.map((item, index) => (
                  <div className="filter-item">
                    <h5>{item.title}</h5>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={item._id}
                      onChange={(e) => handleSubCategoryIdAdd(e, item._id)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        {cart && cart.length > 0 ? (
          <ViewCartButton cartLength={cartLength} />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default StoreTabContent;
