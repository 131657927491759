import React from 'react'

function OrderHistoryLayoutOne({ordernumber,status,date,amount }) {
  return (
    <>
    <div className='order-summery-grid'>
        <p>Order Number : <span>{ordernumber}</span></p>
        <p>Order Status:  <span className={status === 'New' || status === 'Success' ? 'text-success' : 'text-danger'}>{status}</span></p>
        <p>Order Date : <span>{date}</span></p>
        <p>Order Amount : <span>{amount}</span></p>
    </div>
    </>
  )
}

export default OrderHistoryLayoutOne